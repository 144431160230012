<div class="dialog__container">
  <div class="dialog__heading mb-32" mat-dialog-title>
    <h3 class="ebony_color m-0">{{title}}</h3>
    <img src="./app/assets/icons/close-icon.svg" alt="Close" mat-dialog-close class="close-icon" />
  </div>
  <div mat-dialog-content class="dialog__content">
    <p class="mb-32 m-0">{{infoMessage}}</p>
    <div class="button-container-space-between d-flex">
      <button mat-button class="primary-button mobile-no-change" (click)="submit()">{{buttonText}}</button>
      <button mat-button class="secondary-button mobile-no-change ml-20" mat-dialog-close *ngIf="showNoButton">{{noButtonText}}</button>
    </div>
  </div>
</div>
