<div class="dialog__container">
  <div class="dialog__heading mb-32 d-flex" mat-dialog-title>
    <div class="text bold text--x-large">{{title}}</div>
    <img src="./app/assets/icons/close-icon.svg" alt="Close" mat-dialog-close class="cursor-pointer" />
  </div>
  <div mat-dialog-content class="dialog__content">
    <div class="content__image mb-32">
      <img src="./app/assets/images/success-modal-content.svg" alt="thrivepass_logo" />
    </div>
    <div class="text mb-32">
      {{description}}
    </div>
    <div class="d-flex justify-end">
      <button class="button button--primary bold cursor-pointer" [mat-dialog-close] mat-button>Close</button>
    </div>
  </div>
</div>
