<div class="footer-container">
  <div class="icon-container">
    <div class="icon">
      <button mat-button [matMenuTriggerFor]="menu"> <p class="white_color">?</p></button>

      <div class="internal" id="intra">
        <mat-menu #menu="matMenu" class="footer-mat-menu" id="intra">
           <a mat-menu-item href="https://thrivepass.com" target="_blank">About</a>
          <button mat-menu-item class="mt-4" (click)="openSupportDialog()">Support</button>
          <button mat-menu-item class="mt-4" (click)="openTermDialog()">Terms</button>
          <button mat-menu-item class="mt-4" (click)="openPrivacyDialog()">Privacy</button>
        <hr />
        <div class="footer-bottom">© {{ currDate | date : 'yyyy'}} | All Rights Reserved</div>
        </mat-menu>
      </div>

    </div>
  </div>
</div>


