<div class="dialog__heading mb-32" mat-dialog-title>
  <h3 class="ebony_color m-0">{{title}}</h3>
  <img src="./app/assets/icons/close-icon.svg" alt="Close" mat-dialog-close class="cursor-pointer" />
</div>

<div mat-dialog-content class="dialog__content">
  <p class="mb-32 ebony_color">{{message}}</p>

  <div class="align-center d-flex justify-space-between">
    <div class="text-button mr-24" mat-dialog-close>
      <img class="text-button-icon" src="./app/assets/icons/close.svg" />
      <div class="text-button-value">No, Stay in Nexus</div>
    </div>

    <button mat-button class="primary-button mobile-no-change" (click)="submit()">Yes, Return to DE</button>
  </div>
</div>
