import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { AuthService } from '../services/auth.service';
import { CommonService } from '../services/common.service';
import { LoaderService } from '../services/loader.service';

@Injectable({
  providedIn: 'root'
})
export class RedirectGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService, private apiService: ApiService, private commonService: CommonService, private loaderService: LoaderService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn()) {
      this.loaderService.startLoader();
      return this.apiService.get('user/info/get').pipe(map((response: any) => {
        if (response.success) {
          this.commonService.setUserInfo(response.data);
          this.commonService.setIsCookieAccepted(response.data.isCookieAccepted);
          this.commonService.setB2BAccountIdList(response.data.clientsForClientAdmin);
          this.authService.updateUserLastLogin();
          this.commonService.setDecisionEnabledFlag(response.data.decisionEnablement);

          if (state.url == '/login' || state.url.split('?')[0] == '/registration' || state.url == '/') {
            var roles = this.authService.getRoles();
            if (roles.indexOf('participant') > -1) {
              this.router.navigateByUrl('/participant/dashboard');
            }
            if (roles.indexOf('client-admin') > -1) {
              this.router.navigateByUrl('/client/dashboard');
            }
            if (roles.indexOf('tp-admin') > -1) {
              this.router.navigateByUrl('/admin/dashboard');
            }
            if (roles.indexOf('tp-master') > -1) {
              this.router.navigateByUrl('/admin/dashboard');
            }
            if (roles.indexOf('client-reporting') > -1) {
              this.router.navigateByUrl('/client/dashboard');
            }
            if (roles.indexOf('client-master') > -1) {
              this.router.navigateByUrl('/client/dashboard');
            }
          }
          if (state.url == '/admin') {
            this.router.navigateByUrl('/admin/dashboard');
          }
          if (state.url == '/client') {
            this.router.navigateByUrl('/client/dashboard');
          }
        }
        this.loaderService.stopLoader()
        return true;
      }));
    } else if (state.url == '/' && !this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/login');
    }

    return true;
  }
}
