import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ApiService } from './api.service';
import { CommonService } from './common.service';
import { ProgressBarService } from './progress-bar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private msalService: MsalService, private commonService: CommonService, private progressBarService: ProgressBarService, private apiService: ApiService) { }

  async loginRedirect() {
    this.progressBarService.start();
    this.msalService.loginRedirect();
  }

  async logout() {
    this.progressBarService.start();
    this.msalService.logout();
    this.commonService.setActiveB2BAccountId("");
  }

  public isLoggedIn() {
    return this.msalService.getAccount() != null;
  }

  public getRoles(): string[] {
    var userInfo = this.commonService.getUserInfo();
    return (userInfo && userInfo.roles) ? userInfo.roles : [];
  }

  public getUserNameObject(): any {
    const user = this.msalService.getAccount();
    if (user) {
      let nameObject = { 'firstName': user.name, 'fullName': user.name, 'initials': user.name[0], 'username': '', email: '', b2CObjectId: '' };
      nameObject.b2CObjectId = user.accountIdentifier;

      if (user.idToken && user.idToken['given_name'] && user.idToken['family_name']) {
        nameObject.firstName = user.idToken['given_name'];
        nameObject.fullName = user.idToken['given_name'] + ' ' + user.idToken['family_name'];
        nameObject.initials = user.idToken['given_name'][0] + user.idToken['family_name'][0];
      }

      if (user.idToken && user.idToken['extension_DisplayUsername'])
        nameObject.username = user.idToken['extension_DisplayUsername'];

      if (user.idToken && user.idToken['emails'] && user.idToken['emails'].length > 0)
        nameObject.email = user.idToken['emails'][0];

      return nameObject;
    }

    return [];
  }

  public openLoginAsPopupDialog() {
    this.msalService.loginPopup().then((response) => {
      this.commonService.setTokenInfo(response);
    });
  }

  public updateUserLastLogin() {
    this.apiService.post('user/login/update').subscribe((response: any) => {
      if (response.success) {
        //No action required
      }
    }, (error: any) => {     
    });
  }
}
