import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ApiService } from '../../services/api.service';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'accept-cookie-dialog',
  templateUrl: './accept-cookie-dialog.component.html',
  styleUrls: ['./accept-cookie-dialog.component.scss']
})
export class AcceptCookieDialogComponent implements OnInit {
  @Input()
  set setLoggedIn(value: boolean) {
    this.isLoggedIn = value;
  }

  @Output()
  closeCookieInfo = new EventEmitter<any>();

  public message: string = '';
  public browserName: any;
  public fullVersion: any;
  public OSName = "Unknown OS";
  public isLoggedIn: boolean = false;

  constructor(private apiService: ApiService, private commonService: CommonService) {
  }

  public acceptCookie() {
    this.getBrowserInfo();
    var request: any = {
      deviceOSType: this.OSName,
      browserInfo: this.browserName + ' / ' + this.fullVersion
    };

    this.apiService.post('user/cookie/add', request).subscribe((response: any) => {
      if (response.success) {
        this.commonService.setIsCookieAccepted(true);
      }
    });
  }

  ngOnInit(): void {
  }

  public privacyPolicy() {
    window.open("https://www.thrivepass.com/privacy", "_blank");
  }

  public getBrowserInfo() {
    if (navigator.appVersion.indexOf("Win") != -1) this.OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") != -1) this.OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") != -1) this.OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") != -1) this.OSName = "Linux";

    var nAgt = navigator.userAgent;
    this.browserName = navigator.appName;
    this.fullVersion = '' + parseFloat(navigator.appVersion);
    var nameOffset, verOffset, ix;

    // In Opera, the true version is after "OPR" or after "Version"
    if ((verOffset = nAgt.indexOf("OPR")) != -1) {
      this.browserName = "Opera";
      this.fullVersion = nAgt.substring(verOffset + 4);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        this.fullVersion = nAgt.substring(verOffset + 8);
    }
    // In MS Edge, the true version is after "Edg" in userAgent
    else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
      this.browserName = "Microsoft Edge";
      this.fullVersion = nAgt.substring(verOffset + 4);
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
      this.browserName = "Microsoft Internet Explorer";
      this.fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
      this.browserName = "Chrome";
      this.fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
      this.browserName = "Safari";
      this.fullVersion = nAgt.substring(verOffset + 7);
      if ((verOffset = nAgt.indexOf("Version")) != -1)
        this.fullVersion = nAgt.substring(verOffset + 8);
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
      this.browserName = "Firefox";
      this.fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
      (verOffset = nAgt.lastIndexOf('/'))) {
      this.browserName = nAgt.substring(nameOffset, verOffset);
      this.fullVersion = nAgt.substring(verOffset + 1);
      if (this.browserName.toLowerCase() == this.browserName.toUpperCase()) {
        this.browserName = navigator.appName;
      }
    }
    // trim the this.fullVersion string at semicolon/space if present
    if ((ix = this.fullVersion.indexOf(";")) != -1)
      this.fullVersion = this.fullVersion.substring(0, ix);
    if ((ix = this.fullVersion.indexOf(" ")) != -1)
      this.fullVersion = this.fullVersion.substring(0, ix);
  }

  public close() {
    this.closeCookieInfo.emit();
  }
}
