import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import * as moment from 'moment';
import { CommonService } from '../services/common.service';
import { Roles } from '../constants/constant';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private msalService: MsalService, private commonService: CommonService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const accessToken = localStorage.getItem('msal.idtoken');
    const activeB2BAccountId = this.commonService.getActiveB2BAccountdId();
    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`,
          B2BAccountId: activeB2BAccountId || ""
        }
      });
    }
    return next.handle(req);
  }
}
