import { Component } from '@angular/core';
import { Subject } from 'rxjs';
import { AppInsightsService } from './modules/core/services/app-insights/app-insights.service';
import { AuthService } from './modules/core/services/auth.service';
import { CommonService } from './modules/core/services/common.service';
import { ProgressBarService } from './modules/core/services/progress-bar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public isLoggedIn: boolean = false;
  isLoading: Subject<boolean> = this.progressBarService.isLoading;
  public roles: any;
  public userRole!: string;
  public isCookieAccepted: boolean = true;
  public isShowCookieInfo: boolean = false;

  constructor(private authService: AuthService, private progressBarService: ProgressBarService, private commonService: CommonService, private insightService: AppInsightsService) {
    this.isLoggedIn = authService.isLoggedIn();
    if (this.isLoggedIn) {
      this.commonService.checkAndLogoutUser();
      this.commonService.setTimerConfigValue();
      this.commonService.setUserIdleWatch();
      this.commonService.setIsCookieAccepted(this.isCookieAccepted);
      this.participantCookieAccepted();
    } else {
      this.isShowCookieInfo = true;
    }
    this.getRoles();
  }

  ngOnInit(): void {
  }

  public getRoles() {
    if (this.isLoggedIn) {
      let userRole = null;
      var roles = this.authService.getRoles();
      if (roles.indexOf('tp-admin') > -1) {
        this.userRole = 'tp-admin';
      }
      else if (roles.indexOf('client-admin') > -1) {
        this.userRole = 'client-admin';
      }
      else if (roles.indexOf('participant') > -1) {
        this.userRole = 'participant';
      }
    }
  }

  public participantCookieAccepted() {
    this.commonService.isCookieAccepted$.subscribe((response) => {
      this.isCookieAccepted = response;
    });
  }

  public closeCookieInfo() {
    this.isShowCookieInfo = false;
  }
}
