export const SetUpAccountStepSlugs: any = {
  verification: 'verification',
  contact: 'contact',
  credentials: 'credentials'
}

export const CssClass: any = {
  fullScreenClass: 'openFullScreenDialog',
  smallScreenClass: 'openSmallScreenDialog',
  backDropClass: 'backDropForSmallPopUp',
  advanceFullScreenClass: 'fullScreenAdvanceDialog',
  normalDialog: 'normal-dialog',
  advanceFullScreenClassBloom: 'fullScreenAdvanceDialogBloom' //copy of advanceFullScreenClass
};

export const enum PaymentMethod {
  None = 0,
  CreditCard = 1,
  ACH = 2,
  Check = 3,
  Manual = 4
};

export const UserTypes: any = {
  none: 0,
  client: 1,
  participant: 2
}

export const Coverages: any = ['None', 'Single Only', 'Single + 1', 'Single + Spouse', 'Single + Child', 'Single + Children', 'Family']

export const Roles: any = {
  tpmaster: 'tp-master',
  tpadmin: 'tp-admin',
  clientmaster: 'client-master',
  clientadmin: 'client-admin',
  clientreporting: 'client-reporting',
  participant: 'participant',
};
