import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  body: string;
}

@Component({
  selector: 'success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class CommonSuccessDialogComponent implements OnInit {
  public title: string = "Success! Your bank account has been added.";
  public description: string = "";

  constructor(public router: Router, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.title = data.title;
    this.description = data.body;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
