import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'common-notification-dialog',
  templateUrl: './common-notification-dialog.component.html',
  styleUrls: ['./common-notification-dialog.component.scss']
})
export class CommonNotificationDialogComponent {
  public title: string;
  public infoMessage: string;
  public buttonText: string;
  public showNoButton: boolean = false;
  public noButtonText: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CommonNotificationDialogComponent>) {
    this.title = data.title;
    this.infoMessage = data.infoMessage;
    this.buttonText = data.buttonText;
    if (data.showNoButton) {
      this.showNoButton = data.showNoButton;
      this.noButtonText = data.noButtonText;
    }
  }

  public submit() {
    this.dialogRef.close(true);
  }
}
