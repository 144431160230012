import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'move-de-confirmation-dialog',
  templateUrl: './move-de-confirmation-dialog.component.html',
  styleUrls: ['./move-de-confirmation-dialog.component.scss']
})

export class MoveDeConfirmationDialogComponent {
  public title: string = 'Return to DE';
  public message: string = 'As you have not chosen COBRA medical coverage, there are other options at Marketplace. Would you like to proceed?';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<MoveDeConfirmationDialogComponent>) {

  }

  public submit() {
    this.dialogRef.close(true);
  }
}
