import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  title: string;
  body: string;
  blueButtonText: string;
  redirectUrl: string;
}

@Component({
  selector: 'small-popup',
  styleUrls: ['./small-popup-modal.component.scss'],
  templateUrl: './small-popup-modal.component.html'
})

export class SmallPopupModalComponent {
  public title: any;
  public body: any;
  public blueButtonText: any;
  public redirectUrl: any;

  constructor(public dialogRef: MatDialogRef<SmallPopupModalComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router) {
    this.title = data.title;
    this.body = data.body;
    this.blueButtonText = data.blueButtonText;
    this.redirectUrl = data.redirectUrl;
  }

  public redirectRequestUrl() {
    this.dialogRef.close();
    if (this.redirectRequestUrl != null)
      this.router.navigateByUrl(this.redirectUrl);
  }
}
