import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { NoContentComponent } from './modules/core/components/no-content/no-content.component';
import { AdminGuard } from './modules/core/guards/admin.guard';
import { ClientAdminGuard } from './modules/core/guards/client-admin.guard';
import { ParticipantGuard } from './modules/core/guards/participant.guard';
import { RedirectGuard } from './modules/core/guards/redirect.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule), canActivate: [RedirectGuard] },
  { path: 'client', loadChildren: () => import('./modules/client/client.module').then(m => m.ClientModule), canActivate: [MsalGuard, RedirectGuard] },
  { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [MsalGuard, RedirectGuard] },
  { path: 'participant', loadChildren: () => import('./modules/participant/participant.module').then(m => m.ParticpantModule), canActivate: [MsalGuard, RedirectGuard] },
  { path: 'participant-mirror', loadChildren: () => import('./modules/participant-mirror/participant-mirror.module').then(m => m.ParticipantMirrorModule), canActivate: [MsalGuard, RedirectGuard] },
  { path: '**', component: NoContentComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
