import { Pipe } from "@angular/core";

@Pipe({
  name: "phone"
})
export class PhonePipe {
  transform(value: string): string {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
}
