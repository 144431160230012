import { Component, OnInit } from '@angular/core';
import { CssClass, SetUpAccountStepSlugs } from '../../../core/constants/constant';
import { MatDialog } from '@angular/material/dialog';
import { TermOfServiceDialogComponent } from '../../../home/components/term-of-service-dialog/term-of-service-dialog.component';
import { PrivacyPolicyDialogComponent } from '../../../home/components/privacy-policy-dialog/privacy-policy-dialog.component';
import { CommonNotificationDialogComponent } from '../../../shared/notification-dialog/common-notification-dialog/common-notification-dialog.component';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currDate = new Date();

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  public openPrivacyDialog() {
    window.open('https://www.thrivepass.com/privacy', '_blank');
  }

  public openTermDialog() {
    window.open('https://www.thrivepass.com/terms-of-use', '_blank');
  }

  public openSupportDialog() {
    var data: any = {
      infoMessage: 'If you need assistance, please contact ThrivePass Support at cobra@thrivepass.com or 866-855-2844',
      buttonText: 'Close'
    };

    this.dialog.open(CommonNotificationDialogComponent, {
      panelClass: CssClass.normalDialog,
      data: data
    });
  }

}
