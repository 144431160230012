<div class="loading-container" *ngIf="!small">
  <svg version="1.1" class="svg-loader" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" xml:space="preserve">
  <path d="M72 40C72 34.9501 70.8048 29.9718 68.5122 25.4723C66.2196 20.9728 62.8946 17.0797 58.8091 14.1115C54.7236 11.1432 49.9937 9.18396 45.0059 8.39397C40.0181 7.60399 34.9142 8.00568 30.1115 9.56619C25.3087 11.1267 20.9434 13.8017 17.3726 17.3726C13.8017 20.9434 11.1267 25.3087 9.56619 30.1115C8.00568 34.9142 7.60399 40.0181 8.39397 45.0059C9.18396 49.9937 11.1432 54.7236 14.1115 58.8091L16.7003 56.9282C14.0289 53.2513 12.2656 48.9943 11.5546 44.5053C10.8436 40.0163 11.2051 35.4228 12.6096 31.1003C14.014 26.7778 16.4216 22.8491 19.6353 19.6353C22.8491 16.4216 26.7778 14.014 31.1003 12.6096C35.4228 11.2051 40.0163 10.8436 44.5053 11.5546C48.9943 12.2656 53.2513 14.0289 56.9282 16.7003C60.6051 19.3718 63.5976 22.8755 65.661 26.9251C67.7243 30.9746 68.8 35.4551 68.8 40H72Z" fill="url(#paint0_linear)">
    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1.5s" from="0 40 40" to="360 40 40" repeatCount="indefinite" />
  </path>

  <path d="M59.4164 54.1068C61.6426 51.0427 63.112 47.4952 63.7045 43.7544C64.297 40.0136 63.9957 36.1857 62.8254 32.5836C61.655 28.9815 59.6487 25.7076 56.9706 23.0294C54.2924 20.3513 51.0185 18.345 47.4164 17.1746C43.8143 16.0043 39.9864 15.703 36.2456 16.2955C32.5048 16.888 28.9573 18.3574 25.8932 20.5836C22.829 22.8098 20.3353 25.7296 18.6158 29.1042C16.8964 32.4789 16 36.2126 16 40L19.12 40C19.12 36.7049 19.8998 33.4566 21.3958 30.5207C22.8917 27.5847 25.0613 25.0445 27.727 23.1077C30.3928 21.1709 33.4791 19.8925 36.7336 19.3771C39.9882 18.8616 43.3185 19.1237 46.4523 20.1419C49.5861 21.1602 52.4344 22.9056 54.7644 25.2356C57.0944 27.5656 58.8398 30.4139 59.8581 33.5477C60.8763 36.6815 61.1384 40.0118 60.6229 43.2664C60.1075 46.5209 58.8291 49.6072 56.8923 52.273L59.4164 54.1068Z" fill="url(#paint1_linear)">
    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1.2s" from="0 40 40" to="360 40 40" repeatCount="indefinite" />
  </path>

  <path d="M44.9443 55.2169C47.3457 54.4366 49.5283 53.0991 51.3137 51.3137C53.0991 49.5283 54.4366 47.3457 55.2169 44.9443C55.9972 42.5429 56.198 39.9909 55.803 37.497C55.408 35.0032 54.4284 32.6382 52.9443 30.5954C51.4601 28.5527 49.5136 26.8902 47.2638 25.7439C45.0141 24.5976 42.525 24 40 24C37.475 24 34.9859 24.5976 32.7362 25.7439C30.4864 26.8902 28.5399 28.5527 27.0557 30.5954L29.6446 32.4763C30.8319 30.8422 32.3891 29.5122 34.1889 28.5951C35.9887 27.6781 37.98 27.2 40 27.2C42.02 27.2 44.0113 27.6781 45.8111 28.5951C47.6109 29.5122 49.1681 30.8422 50.3554 32.4763C51.5427 34.1105 52.3264 36.0025 52.6424 37.9976C52.9584 39.9927 52.7977 42.0343 52.1735 43.9554C51.5493 45.8765 50.4793 47.6226 49.051 49.051C47.6226 50.4793 45.8765 51.5493 43.9554 52.1735L44.9443 55.2169Z" fill="url(#paint2_linear)">
    <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 40 40" to="360 40 40" repeatCount="indefinite" />
  </path>

  <defs>
    <linearGradient id="paint0_linear" x1="8" y1="8" x2="61.4696" y2="76.4095" gradientUnits="userSpaceOnUse">
      <stop offset="0.00877465" stop-color="#4C5AEA" />
      <stop offset="1" stop-color="#202561" />
    </linearGradient>
  
    <linearGradient id="paint1_linear" x1="16" y1="16" x2="56.1022" y2="67.3071" gradientUnits="userSpaceOnUse">
      <stop offset="0.00877465" stop-color="#4C5AEA" />
      <stop offset="1" stop-color="#202561" />
    </linearGradient>
  
    <linearGradient id="paint2_linear" x1="24" y1="24" x2="50.7348" y2="58.2047" gradientUnits="userSpaceOnUse">
      <stop offset="0.00877465" stop-color="#4C5AEA" />
      <stop offset="1" stop-color="#202561" />
    </linearGradient>
  </defs>
</svg>
</div>

<div *ngIf="small">
  <svg class="svg-small-loader" width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.2409 8.15479C24.2409 7.07637 23.3635 6.19099 22.2913 6.30689C13.9433 7.20933 7.31236 13.8403 6.40992 22.1883C6.29401 23.2604 7.1794 24.1378 8.25782 24.1378V24.1378C9.33623 24.1378 10.1962 23.2587 10.3445 22.1905C11.2041 16.0002 16.1027 11.1011 22.2935 10.2415C23.3617 10.0932 24.2409 9.2332 24.2409 8.15479V8.15479Z" fill="#343DA0" />
    <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 24 24" to="360 24 24" dur="0.6s" repeatCount="indefinite" />
  </svg>
</div>
