<div class="top-banner" *ngIf="data">
  <p class="ebony message mb-0 mt-0" *ngIf="data.message && data.message !== ''">{{data.message}}</p>

  <div class="button-container">
    <div class='submit-button' *ngIf="data && data.actionButtonText && data.actionButtonText !== ''">
      <a mat-button class="mat-button__skeleton mat-button__disable-ripple cursor-pointer" (click)="performAction()">
        <div class="overline sapphire">{{data.actionButtonText}}</div>
      </a>
    </div>
  </div>

  <div class="close">
    <mat-icon mat-button class="cursor-pointer" matSuffix (click)="dismiss()">close</mat-icon>
  </div>
</div>
