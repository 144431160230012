import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { NoContentComponent } from './components/no-content/no-content.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthService } from './services/auth.service';
import { MsalInterceptor, MsalModule, MSAL_CONFIG } from '@azure/msal-angular';
import { RedirectGuard } from './guards/redirect.guard';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
//import { NgProgressModule } from 'ngx-progressbar';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { TopBannerComponent } from './components/top-banner/top-banner.component';
import { AdminGuard } from './guards/admin.guard';
import { ClientAdminGuard } from './guards/client-admin.guard';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { SmallPopupModalComponent } from './components/small-popup/small-popup-modal.component';
import { PhonePipe } from './pipes/format-phone.pipe';
import { ParticipantGuard } from './guards/participant.guard';
import { UserIdleService } from './services/user-idle.service';
import { AcceptCookieDialogComponent } from './components/accept-cookie-dialog/accept-cookie-dialog.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NoContentComponent,
    LoaderComponent,
    TopBannerComponent,
    TitleBarComponent,
    SmallPopupModalComponent,
    PhonePipe,
    AcceptCookieDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NoContentComponent,
    LoaderComponent,
    HttpClientModule,
    TopBannerComponent,
    TitleBarComponent,
    SmallPopupModalComponent,
    PhonePipe,
    AcceptCookieDialogComponent
  ],
  providers: [
    //Services
    AuthService,

    //Guards
    RedirectGuard,
    AdminGuard,
    ClientAdminGuard,
    ParticipantGuard,
    UserIdleService,

    //Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ]
})
export class CoreModule { }
